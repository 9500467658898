// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-video-article-js": () => import("/opt/build/repo/src/templates/video-article.js" /* webpackChunkName: "component---src-templates-video-article-js" */),
  "component---src-templates-publications-js": () => import("/opt/build/repo/src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */),
  "component---src-templates-resource-category-js": () => import("/opt/build/repo/src/templates/resource-category.js" /* webpackChunkName: "component---src-templates-resource-category-js" */),
  "component---src-templates-team-member-js": () => import("/opt/build/repo/src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-jsx": () => import("/opt/build/repo/src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("/opt/build/repo/src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

